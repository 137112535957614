<template>
    <div class="dosiin_page-min-height dosiin_padding-header-top">
        <div class="ds_notification-inner">
            <div class="ds_notification-right">
                <div class="ds_notification-box">
                    <div class="ds_notification-back_header">
                        <div class="ds_notification-go-back" @click="goBack">
                            <i class="dsi dsi-chevron-left"></i>
                            <a>Thông báo</a>
                        </div>
                        <h2 class="text-grey-lg">Khuyến mãi</h2>
                        <div class="ds_notification-dot-event"></div>
                    </div>
                    <div class="ds_notification-scrolly d_noti-txtbox">
                        <div class="ds_notification-scrolly-content">
                            <section class="notification-mag-new pd_bt" v-if="state.promotions.length">
                                <div class="notification-new-deal_wrapper read" v-for="(promo,i) in state.promotions" :key="i">
                                    <div class="icon-left">
                                        <img :src="_promotion" />
                                    </div>
                                    <div class="notification-description">
                                        <div class="notification-description_img notification-item_img-wrapper">
                                            <a :href="promo.link">
                                                <img class="name-notification" v-lazy="_eventThumbnail(promo.main_pair)" width="55" height="55" :alt="promo.name">
                                            </a>
                                        </div>
                                        <div class="notification-description-event_info">
                                            <a :href="promo.link">
                                                <h5 class="notification-item_title truncate" v-text="promo.name"></h5>
                                                <span class="notification-item_title-sub">Sự kiện diễn ra: {{ _momentDateMonth(promo.from_date)}} - {{_momentDateMonth(promo.to_date)}}</span>
                                                <p class="time" v-text="_momentFromNow(promo.from_date)"></p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ds_notification-left">
                <div class="brand-list-header header-section">
                    <h3 class="brand-suggested-brands_title">Thương hiệu bạn có thể biết</h3>
                    <div class="view-all">
                        <router-link class="primary-button" :to="{ name : 'brand'}" title="Xem tất cả thương hiệu">
                            <span class="primary-link_text">Toàn bộ</span>
                            <i class="dsi dsi-act-arrow-forward"></i>
                        </router-link>
                    </div>
                </div>
                <div class="dosiin_suggested-brands_slider suggested-brands_slider">
                    <ul class="ds_notification-left_list">
                        <li class="ds_notification-left_item" v-for="(brand,i ) in state.brands.slice(0,2)" :key="i">
                            <BrandItemVertical :brand="brand" />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { inject } from 'vue';
    import BrandItemVertical from '@/components/brand/BrandItemVertical';
    export default {
        components:{
            BrandItemVertical
        },
        setup() {
            
            const state = inject('state');

            return{ 
                state
            }
        },
    }
</script>