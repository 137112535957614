<template>
  <NotificatonsPromoMB v-if="layout == 4" />
  <NotificatonsPromoPC v-else />
</template>

<script>
import NotificatonsPromoPC from "@/pages/pc/notifications/NotificationsPromo";
import NotificatonsPromoMB from "@/pages/mb/notifications/NotificationsPromo";
import PromotionService from "@/services/promotion";
import { reactive, provide } from "vue";
import BrandServices from "@/services/brand";
import { serialize } from "@/utlis/serialize";
export default {
  components: {
    NotificatonsPromoPC,
    NotificatonsPromoMB,
  },
  setup() {
    const state = reactive({
      promotions: [],
      brands: [],
      loading: true,
    });

    getPromotions();
    getBrands();

    async function getPromotions() {
      const response = await PromotionService.fetchPromotions();
      if (response.data) {
        state.promotions = response.data.promotions;
      }
    }

    async function getBrands() {
      const response = await BrandServices.fetchBrands(
        serialize({
          limit: 2,
          use_caching: true,
          get_banner: true,
          sort_by: "random",
        })
      );

      if (response.data) {
        state.brands = response.data.brands;
      }
    }

    provide("state", state);
  },
};
</script>
