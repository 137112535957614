<template>
    <div class="modal modal-full-screen dosiin_promotions-modal open">
        <div class="modal-header">
            <header style="display: unset;">
                <div class="back-header dosiin_back-header-sticky">
                    <div class="go-back-icon">                    
                        <i class="dsi dsi-chevron-left"></i>                    
                        <button class="dosiin_btn-close-modal text" @click="goBack">Quay lại</button>                
                    </div>
                    <h2 class="text-grey-lg">
                        <div style="display:block;">Khyến mãi</div>
                    </h2>
                    <div class="back-header-right-side"></div>
                </div>
            </header>
        </div>
        <div class="modal-body">
            <main class="notifications-events-page">
                <ul class="promo-list" id="dosiin_coupon-list" v-if="state.promotions.length">
                    <li class="promo-item" v-for="(promo,i) in state.promotions" :key="i">
                        <svg viewBox="0 0 339 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M256 12C259.826 12 263.234 10.2094 265.431 7.42042C268.166 3.9499 271.582 0 276 0H331C335.418 0 339 3.58172 339 8V62C339 66.4183 335.418 70 331 70H276C271.582 70 268.166 66.0501 265.431 62.5796C263.234 59.7906 259.826 58 256 58C252.174 58 248.766 59.7906 246.569 62.5796C243.834 66.0501 240.418 70 236 70H8C3.58173 70 0 66.4183 0 62V8C0 3.58172 3.58172 0 8 0H236C240.418 0 243.834 3.94989 246.569 7.42042C248.766 10.2094 252.174 12 256 12ZM256 15C255.724 15 255.5 15.2239 255.5 15.5V18.3333C255.5 18.6095 255.724 18.8333 256 18.8333C256.276 18.8333 256.5 18.6095 256.5 18.3333V15.5C256.5 15.2239 256.276 15 256 15ZM256.5 21.6667C256.5 21.3905 256.276 21.1667 256 21.1667C255.724 21.1667 255.5 21.3905 255.5 21.6667V28.3333C255.5 28.6095 255.724 28.8333 256 28.8333C256.276 28.8333 256.5 28.6095 256.5 28.3333V21.6667ZM256.5 31.6667C256.5 31.3905 256.276 31.1667 256 31.1667C255.724 31.1667 255.5 31.3905 255.5 31.6667V38.3333C255.5 38.6095 255.724 38.8333 256 38.8333C256.276 38.8333 256.5 38.6095 256.5 38.3333V31.6667ZM256.5 41.6667C256.5 41.3905 256.276 41.1667 256 41.1667C255.724 41.1667 255.5 41.3905 255.5 41.6667V48.3333C255.5 48.6095 255.724 48.8333 256 48.8333C256.276 48.8333 256.5 48.6095 256.5 48.3333V41.6667ZM256.5 51.6667C256.5 51.3905 256.276 51.1667 256 51.1667C255.724 51.1667 255.5 51.3905 255.5 51.6667V54.5C255.5 54.7761 255.724 55 256 55C256.276 55 256.5 54.7761 256.5 54.5V51.6667Z" fill="white"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M329 0C333.418 0 337 3.58172 337 8V62C337 66.4183 333.418 70 329 70H331C335.418 70 339 66.4183 339 62V8C339 3.58172 335.418 0 331 0H329Z" fill="url(#paint0_linear_10873_28737)"></path>
                            <defs>
                                <linearGradient id="paint0_linear_10873_28737" x1="329" y1="0" x2="348.6" y2="2.8" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#ED55C7"></stop>
                                    <stop offset="1" stop-color="#6756CA"></stop>
                                </linearGradient>
                            </defs>
                        </svg>
                        <div class="info-coupon">
                            <div class="promo-img">                    
                                <img class="img-coupon" v-lazy="_eventThumbnail(promo.main_pair)" width="100" height="100" />
                            </div>
                            <div class="promo-content">
                                <span class="group-promo-name d-flex">                        
                                    <span class="dosiin_mr-2 promo-name promo-name-freeship">                                                            
                                        Mã giảm giá
                                    </span>                        
                                    <span class="dosiin_mr-2 promo-name promo-name promo-name-brand">                                                            
                                        Dosiin
                                    </span>                                            
                                </span>                    
                                <span class="promo-detail" v-text="promo.name"></span>                    
                                <div class="group-info d-flex">                        
                                    
                                    <Popper 
                                        arrow 
                                        disableClickAway 
                                    >
                                        <button type="button">
                                            <i class="dsi dsi-info dsi-s12 dosiin_mr-2"></i>
                                        </button>

                                        <template #content>
                                            <div class="dosiin_coupon-detail">
                                                <div class="dosiin_coupon-detail-promotion dosiin_coupon-detail-promotion-modal-overlay  modal">
                                                    <div class="close-coupon dosiin_float-right">           
                                                        <img class="name-notification" width="36" height="36" src="https://dosi-in.com/images/assets/icons/close-coupon.png" alt="event">     
                                                    </div>
                                                    <div class="img-box center">            
                                                        <img class="name-notification" width="339" height="190" src="https://dosi-in.com/images/assets/icons/gif-box-coupon.png" alt="event">        
                                                    </div>
                                                    <div class="content-coupon">
                                                        <div class="detail-coupon" id="dosiin_promotion-content" v-html="promo.content_make_up"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </Popper>                      
                                    <span class="promo-exp">HSD: 
                                        <span class="text-pink-gradient">{{ _momentDateMonth(promo.from_date)}} - {{ _momentDateMonth(promo.to_date)}}</span>
                                    </span>                    
                                </div>
                            </div>
                            <div class="copy-promo">                                            
                                <a class="btn copy-promo-btn d-flex justify-content-center align-items-center center text-pink-gradient" :href="promo.link"> 
                                    <span class="text-pink-gradient dosiin_d-block">Sử dụng</span>
                                </a>                                    
                            </div>
                        </div>
                    </li>
                </ul>
            </main>
        </div>
    </div>
</template>

<script>
    import { inject } from 'vue';
    import Popper from "vue3-popper";
    export default {
        components:{
            Popper
        },
        setup() {
            const state = inject('state');

            return{ 
                state
            }
        },
    }
</script>